import React from 'react';

const Poster = () => {
  return (
    <div className="relative bg-gradient-to-r from-blue-500 via-blue-700 to-indigo-800 w-full h-screen md:h-auto text-white p-8 rounded-lg shadow-lg overflow-hidden">
      <div className="absolute inset-0 bg-opacity-40 bg-black"></div>
      <div className="relative z-10  mt-10 md:mt-0 flex flex-col items-center justify-center text-center">
        <img 
          src="/players.webp" 
          alt="Cricket Match" 
          className="w-full h-auto max-h-[500px]  object-left-top rounded-xl mb-6 shadow-lg"
        />
        <h2 className="text-4xl mt-20 md:mt-0 md:text-5xl font-extrabold mb-4 text-yellow-400">Live Cricket Updates, Anytime, Anywhere!</h2>
        <p className="text-lg md:text-xl mb-6">Get the latest match scores, breaking news, and expert commentary from the world of cricket.</p>
        <a href="https://wa.link/ggnow" target="_blank" rel="noopener noreferrer">
          <button className="bg-yellow-500 text-black hover:bg-yellow-400 font-semibold py-3 px-8 rounded-md shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
            Join the Action Now!
          </button>
        </a>
      </div>
    </div>
  );
};

export default Poster;
